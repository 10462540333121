define('ember-scroll-to/services/scroller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var DURATION = 750;
  var EASING = 'swing';
  var OFFSET = 0;

  exports['default'] = _ember['default'].Service.extend({

    // ----- Static properties -----
    duration: DURATION,
    easing: EASING,
    offset: OFFSET,

    // ----- Computed properties -----
    scrollable: _ember['default'].computed(function () {
      return _ember['default'].$('html, body');
    }),

    // ----- Methods -----
    getJQueryElement: function getJQueryElement(target) {
      var jQueryElement = _ember['default'].$(target);

      if (!jQueryElement) {
        _ember['default'].Logger.warn("element couldn't be found:", target);
        return;
      }

      return jQueryElement;
    },

    getVerticalCoord: function getVerticalCoord(target) {
      var offset = arguments.length <= 1 || arguments[1] === undefined ? 0 : arguments[1];

      var jQueryElement = this.getJQueryElement(target);
      return jQueryElement.offset().top + offset;
    },

    scrollVertical: function scrollVertical(target) {
      var opts = arguments.length <= 1 || arguments[1] === undefined ? {} : arguments[1];

      this.get('scrollable').animate({
        scrollTop: this.get('scrollable').scrollTop() - this.get('scrollable').offset().top + this.getVerticalCoord(target, opts.offset)
      }, opts.duration || this.get('duration'), opts.easing || this.get('easing'), opts.complete);
    }
  });
});