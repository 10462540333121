define('ember-linkify/helpers/linkify', ['exports', 'ember', 'ember-linkify/utils/url-regex'], function (exports, _ember, _emberLinkifyUtilsUrlRegex) {
  'use strict';

  exports.linkify = linkify;

  var ALLOWED_ATTRIBUTE_NAMES = ['rel', 'class'];

  function linkify(params, options) {
    var textToLinkify = _ember['default'].Handlebars.Utils.escapeExpression(params[0]);
    var windowTarget = params[1] || "_self";
    var sharedAttributes = opts2attrs(options);

    textToLinkify = textToLinkify.replace((0, _emberLinkifyUtilsUrlRegex.urlRegex)(), function (s) {
      var url = undefined;
      var displayText = s.trim();

      if (s.trim().match(/^www\./ig)) {
        url = '//' + s.trim();
      } else {
        url = s.trim();
      }

      if (options && options.urlLength && options.urlLength > 0) {
        displayText = (0, _emberLinkifyUtilsUrlRegex.shortenUrl)(displayText, options.urlLength);
      }

      return ' <a href="' + url + '" target="' + windowTarget + '"' + sharedAttributes + '>' + displayText + '</a> ';
    });

    return _ember['default'].String.htmlSafe(textToLinkify);
  }

  exports['default'] = _ember['default'].Helper.helper(linkify);

  function opts2attrs(options) {
    var stringOfAttributes = [''];

    if (_ember['default'].typeOf(options) === 'object') {
      for (var i = 0; i < ALLOWED_ATTRIBUTE_NAMES.length; i++) {
        var attributeName = ALLOWED_ATTRIBUTE_NAMES[i];
        if (attributeName in options) {
          stringOfAttributes.push(attributeName + '="' + options[attributeName] + '"');
        }
      }
    }

    return stringOfAttributes.join(' ');
  }
});